<template>
  <div class="report-filter">
    <form class="report-filter-form" :action="url_path">
      <div class="row">
        <div class="col-12 col-md-4 form-group">
          <label for="user-filter">Пользователь</label>
          <input v-model="user" type="text" id="user-filter" class="form-control" name="user">
        </div>

        <div class="col-12 col-md-4 form-group">
          <label for="date-filter">Дата</label>
          <date-picker id="date-filter" v-model="date" :config="date_options" name="date" @dp-hide="addValueToDate" autocomplete="off"></date-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 form-group">
          <label for="date-filter">Статус оплаты</label>
          <select name="paid" id="paid-filter" class="form-control" v-model="paid">
            <option v-for="paid in paid_options" :value="paid.value">
              {{ paid.name }}
            </option>
          </select>
        </div>

        <div class="col-12 col-md-4 form-group" v-if="products.length > 0">
          <label for="products-filter">Продукты</label>
          <select v-model="product" name="products[]" id="products-filter" class="form-control" multiple>
            <option v-for="product in products" :key="product.id" :value="product.id">
              {{ product.name }}
            </option>
          </select>
        </div>
      </div>
      <input id="user" type="hidden" name="q">
    </form>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';

import datePicker from 'vue-bootstrap-datetimepicker';
  
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'

export default {
  data () {
    return {
      date: '',
      date_options: {
        format: 'MM-YYYY',
        useCurrent: false,
        locale: "ru"
      },
      paid_options: [
        { name: 'Все', value: '' },
        { name: 'Только неоплаченные', value: 0 },
        { name: 'Только оплаченные', value: 1 }
      ],
      paid: '',
      user: '',
      product: [],
      form: undefined
    }
  },
  methods: {
    addValueToDate() {
      this.form.submit();
    }
  },
  components: {
    datePicker
  },
  name: 'ReportFilter',
  props: {
    products: {
      type: Array,
      default: () => []
    },
    filter: {},
    url_path: {
      type: String
    }
  },
  mounted() {
    let filter = this;
    console.log(this)
    console.log(this.url_path)
    this.form = document.querySelector('.report-filter-form');
    this.form.addEventListener('change', function() {
      this.submit();
    });

    if(this.filter.hasOwnProperty('date')) {
      this.date = this.filter.date;
    }

    if(this.filter.hasOwnProperty('paid')) {
      this.paid = this.filter.paid;
    }

    if(this.filter.hasOwnProperty('products')) {
      this.product = this.filter.products;
    }

    if(this.filter.hasOwnProperty('user')) {
      this.user = this.filter.user;
    }
  }
}
</script>

<style lang='scss'>
  #products-filter {
    min-height: 150px;
  }
</style>
